<template>
  <div class="game-container">
    <ChooseImgGame
      :allImgList="allImgList"
      :buttonList="buttonList"
      :answerList="answerList"
    />
  </div>
</template>

<script>
import ChooseImgGame from "@/components/Course/GamePage/ChooseImgGame";
export default {
  data() {
    return {
      answerList: [2, 5, 9],
      allImgList: [
        {
          pinyin:"Tā zǎoshang bā diǎn qǐchuáng.",  
          hanzi:"她早上八点起床。",
          imgList:[{
            bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/image-1-1.svg`),
            id:1,
            color:'#fff'
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/image-1-2.svg`),
            id:2,
            color:'#fff'
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/image-1-3.svg`),
            id:3,
            color:'#fff'
          }],
        },
        {
          pinyin:"Tāmen xiàwǔ qù dòngwùyuán.",  
          hanzi:"他们下午去动物园。",
          imgList:[{
            bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/image-2-1.svg`),
            id:4,
            color:'#fff'
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/image-2-2.svg`),
            id:5,
            color:'#fff'
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/image-2-3.svg`),
            id:6,
            color:'#fff'
          }],
        },
        {
          pinyin:"Qǐng búyào shuìjiào.",  
          hanzi:"请不要睡觉。",
          imgList:[{
            bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/image-3-1.svg`),
            id:7,
            color:'#fff'
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/image-3-2.svg`),
            id:8,
            color:'#fff'
          },
          {
            bgImg: require(`@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/image-3-3.svg`),
            id:9,
            color:'#fff'
          }]
        },
      ],
      buttonList: [
        {
          image: require("@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/menu-1.svg"),
          audioSrc: require("@/assets/audio/chapter_1/ni-hao.mp3"),
          isCircle: true,
          startNumberRange: 1,
          endNumberRange: 1,
        },
        {
          image: require("@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/menu-2.svg"),
          audioSrc: require("@/assets/audio/chapter_1/zaijian.mp3"),
          isCircle: true,
          startNumberRange: 2,
          endNumberRange: 2,
        },
        {
          image: require("@/assets/img/16-GAMES/G65-stories-3-cards/l2-lesson-02/menu-3.svg"),
          audioSrc: require("@/assets/audio/chapter_1/zaijian.mp3"),
          isCircle: true,
          startNumberRange: 3,
          endNumberRange: 3,
        },
      ],
    };
  },
 
  components: {
    ChooseImgGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>